import React, { useState, useEffect } from "react";
import { Grid, LinearProgress, Typography, Box } from "@mui/material";
import ManualPolicyIssuance from "./ManualPolicyIssuance";
import AutomaticPolicyIssuance from "./AutomaticPolicyIssuance";
import "./policy.issuance.sass";
import { getFreeStickerAndGreenCard } from "../../../../services/PoliciesService";
import { error as errorAlert } from "../../../../utils/notifications";
import { isAutomaticStickersActive } from "../../../../config/mainConfig/helpers";
import ManualAutomaticRadioButtons from "../../common/ManualAutomaticRadioButtons";
import { danger } from "../../../../utils/colors";
import _get from "lodash.get";

const PolicyIssuance = props => {
  const [selectedTab, setSelectedTab] = useState("automatic");
  const [loading, setLoading] = useState(false);
  const [parentLoading, setParentLoading] = useState(false);
  const [sticker, setSticker] = useState("");
  const [greenCard, setGreenCard] = useState("");
  const [radiosDisabled, setRadiosDisabled] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setParentLoading(true);
        const response = await getFreeStickerAndGreenCard(props?.policy?.insurerId);
        const {
          data: { sticker, greenCard }
        } = response || {};
        setSticker(sticker);
        setGreenCard(greenCard);
      } catch (e) {
        errorAlert(e?.response?.data);
      }
      setParentLoading(false);
    }
    if (isAutomaticStickersActive(props?.policy?.insurerId, props?.policy?.productId)) {
      fetchData();
    }

    if (_get(props.policy, "policyData.isNotRegisteredVehicle", false)) {
      setSelectedTab("manual");
      setRadiosDisabled(true);
    }
    let isForeigner = false;
    const ownerData = _get(props, "policy.policyData.savedCar.ownerData");
    if (ownerData) {
      isForeigner = ownerData?.country !== "BG";
    } else {
      const insuredPersons = _get(props, "policy.policyData.persons", []).filter(p => p.isInsured === undefined || p.isInsured);
      const owner = _get(insuredPersons, "[0]", {});
      isForeigner = owner?.isForeigner;
    }
    if (isForeigner) {
      setSelectedTab("manual");
      setRadiosDisabled(true);
    }
  }, []);

  const handleChange = e => {
    setSelectedTab(e?.target?.value);
  };

  const fallbackValues = {
    carNo: "Няма намерени данни в КАТ",
    address: "Няма намерен адрес в КАТ",
    ownerFullName: "Няма намерени имена в КАТ",
    postCode: "Няма пощенски код в КАТ",
    cityName: "Няма данни за град в КАТ"
  };

  const extractOwnerInfo = (policy, fallbackValues) => {
    const { savedCar } = policy?.policyData || {};
    const { ownerData = {}, carNo, vehicleData = {} } = savedCar || {};
    const { address, postCode, cityName, firstName, middleName, lastName, companyName } = ownerData;
    let ownerFullName = "";
    if (ownerData?.eik) {
      ownerFullName = firstName || companyName;
    } else {
      ownerFullName = firstName && middleName && lastName ? `${firstName} ${middleName} ${lastName}` : fallbackValues.ownerFullName;
    }

    return {
      carNo: carNo || vehicleData?.vehRegPlate || fallbackValues.carNo,
      address: address || fallbackValues.address,
      ownerFullName: ownerFullName,
      postCode: postCode || fallbackValues.postCode,
      cityName: cityName || fallbackValues.cityName
    };
  };

  const renderOwnerInfo = (label, value, fallback) => (
    <Typography variant="subtitle1">
      <strong>{label} : </strong>
      <span style={{ color: value === fallback ? danger : "initial" }}>{value}</span>
    </Typography>
  );

  const { carNo, cityName, postCode, address, ownerFullName } = extractOwnerInfo(props.policy, fallbackValues);

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "75%" }}>
          <Box sx={{ borderBottom: 1, borderTop: 1 }} mb={2}>
            {renderOwnerInfo("Име", ownerFullName, fallbackValues.ownerFullName)}
            {renderOwnerInfo("Автомобил №", carNo, fallbackValues.carNo)}
            {renderOwnerInfo("Град", cityName, fallbackValues.cityName)}
            {renderOwnerInfo("Адрес", address, fallbackValues.address)}
            {renderOwnerInfo("Пощенски код", postCode, fallbackValues.postCode)}
          </Box>

          <ManualAutomaticRadioButtons loading={loading} handleChange={handleChange} selectedTab={selectedTab} disabled={radiosDisabled} />

          {!parentLoading ? (
            <>
              {selectedTab === "manual" && <ManualPolicyIssuance {...props} loading={loading} setLoading={setLoading} sticker={sticker} greenCard={greenCard} />}
              {selectedTab === "automatic" && (
                <AutomaticPolicyIssuance {...props} loading={loading} setLoading={setLoading} sticker={sticker} greenCard={greenCard} setRadiosDisabled={setRadiosDisabled} />
              )}
            </>
          ) : (
            <LinearProgress sx={{ my: 2 }} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default PolicyIssuance;
