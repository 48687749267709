import React, { Component } from "react";
import { uploadFile, uploadPolicyFile, updatePolicyDuringIssuance } from "../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../utils/notifications";
import _get from "lodash.get";
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, InputAdornment } from "@mui/material";
import { primary, danger, gray, grayLight, grayBorder } from "../../utils/colors";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import moment from "moment/moment";

// props policy,
class UploadFileForm extends Component {
  constructor(props) {
    super(props);
    this.handleDate = this.handleDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleTxtChange = this.handleTxtChange.bind(this);
    this.state = {
      policyNumber: _get(props, "policy.policyId"),
      date: _get(props, "policy.policyData.validFrom") ? moment(_get(props, "policy.policyData.validFrom")).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
      file: null,
      typeDocument: props?.isFromWizard ? props?.typeDocument : "",
      isHidden: false,
      isLoading: false,
      fileName: ""
    };
  }

  handleTxtChange(e, name) {
    this.setState(
      {
        [name]: _get(e, "target.value", "").trim()
      },
      () => {
        if (name === "typeDocument" && ["policy", "receipt", "vdog"].includes(_get(e, "target.value", ""))) {
          this.setState({
            isHidden: false
          });
        }
      }
    );
  }

  onFileChange(e) {
    if (e.target.files[0].size > 1024 * 1024 * 10) {
      errorAlert("Размерът на файла не може да е по-голям от 10 MB.");
      this.setState({ file: null });
      return false;
    } else {
      this.setState({ file: e.target.files[0] });
      this.setState({ file: e?.target?.files[0], fileName: e?.target?.files[0]?.name });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      const { typeDocument } = this.state;
      this.setState({ isLoading: true });
      if (typeDocument === "policy") {
        if (this.props?.isFromWizard) {
          const { passedData } = this.props;
          await uploadPolicyFile({ ...this.state, ...this.props, policyNumber: passedData.policyNumber });
          await this.props?.canCurrentStepContinue();
        } else {
          await updatePolicyDuringIssuance({ ...this.state, ...this.props });
          await uploadPolicyFile({ ...this.state, ...this.props });
        }
      } else if (["otherFile", "receipt", "vdog", "installment"].includes(typeDocument)) {
        await uploadFile({ ...this.state, ...this.props });
        if (this.props?.isFromWizard) {
          await this.props?.canCurrentStepContinue();
        }
      }
      this.setState({ isLoading: false });
      successAlert("uploaded successfully!");
    } catch (e) {
      this.setState({ isLoading: false });
      errorAlert(_get(e, "response.data"));
    }
    this.props.refreshData();
  }

  handleDate(e) {
    this.setState({
      date: e.target.value
    });
  }

  renderForm = () => {
    const { policyNumber, date, typeDocument, file, isHidden, isLoading } = this.state;
    const { type, isFromWizard, policy } = this.props;
    let btnDisabled;
    if (isLoading) {
      btnDisabled = true;
    } else {
      if (["receipt", "vdog"].includes(typeDocument)) {
        btnDisabled = !typeDocument;
      } else if (["otherFile", "receiptInsurer", "greencard", "installment"].includes(typeDocument)) {
        btnDisabled = !(typeDocument && file);
      } else {
        btnDisabled = !(policyNumber && date && typeDocument && file);
        if (isFromWizard) {
          btnDisabled = !(typeDocument && file);
        }
      }
    }
    return (
      <form onSubmit={this.handleSubmit}>
        {!policyNumber && type === "installments" && <p style={{ color: danger }}> Липсва номер на полица(трябва да се въведе от ниво &quot;полици&quot; &gt; /policies)</p>}
        {type === "policies" && !isFromWizard && (
          <>
            <Box mb={2} style={{ display: ["otherFile", "receipt", "vdog", "installment"].includes(typeDocument) ? "none" : "" }}>
              <TextField
                fullWidth
                label="Номер на полицата"
                id="outlined-size-small"
                variant="outlined"
                size="small"
                value={policyNumber}
                onChange={e => this.handleTxtChange(e, "policyNumber")}
                inputProps={{
                  style: {
                    color: gray
                  }
                }}
              />
            </Box>
            <Box mb={2} style={{ display: ["otherFile", "receipt", "vdog", "installment"].includes(typeDocument) ? "none" : "" }}>
              <TextField
                fullWidth
                onChange={this.handleDate}
                id="datetime-local"
                label="Начална дата"
                type="date"
                value={this.state.date}
                inputProps={{
                  style: {
                    color: gray
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
          </>
        )}
        {_get(this.props, "policy.productId") === "GO" && isFromWizard && <p className="has-text-centered">{policy?.policyData?.goRegNumber}</p>}
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="doc-type-label" style={{ display: isFromWizard ? "none" : "block" }}>
              Вид на документа
            </InputLabel>
            <Select
              style={{ display: isFromWizard ? "none" : "block" }}
              // readOnly={isFromWizard}
              labelId="doc-type-label"
              id="doc-type-select"
              value={typeDocument}
              label="Вид на документа"
              onChange={e => this.handleTxtChange(e, "typeDocument")}
              SelectDisplayProps={{
                style: { fontSize: 16, color: gray }
              }}
            >
              {type === "policies" && <MenuItem value={"policy"}>Полица</MenuItem>}
              {type === "policies" && <MenuItem value={"receipt"}>Разписка</MenuItem>}
              {type === "policies" && <MenuItem value={"vdog"}>Възлагателен договор</MenuItem>}
              <MenuItem value={"otherFile"}>Друг файл</MenuItem>
              <MenuItem value={"installment"}>Вноска</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FormControlLabel
          style={{ display: ["policy", "receipt", "vdog"].includes(typeDocument) ? "none" : "" }}
          label="Скрит файл"
          control={
            <Checkbox
              checked={isHidden}
              onChange={() => {
                this.setState(prevState => {
                  return {
                    isHidden: !prevState.isHidden
                  };
                });
              }}
            />
          }
        />
        <Box mb={2} style={{ display: ["receipt", "vdog"].includes(typeDocument) ? "none" : "" }}>
          <TextField
            label=""
            id="outlined-start-adornment"
            readOnly
            style={{ width: "100%" }}
            value={this.state.fileName}
            placeholder="Няма избран файл"
            inputProps={{
              style: {
                padding: 9,
                color: gray
              }
            }}
            InputProps={{
              sx: {
                padding: 0
              },
              endAdornment: (
                <InputAdornment sx={{ height: "100%" }} position="end">
                  <Button
                    variant="contained"
                    color="inherit"
                    component="label"
                    sx={{
                      ml: 1,
                      py: "8px",
                      backgroundColor: grayLight,
                      boxShadow: "none",
                      borderLeft: `1px solid ${grayBorder}`
                    }}
                  >
                    Прикачи файл
                    <input type="file" hidden onChange={this.onFileChange} />
                  </Button>
                </InputAdornment>
              )
            }}
          />
        </Box>
        {isFromWizard ? (
          <Box mb={2} mt={2} display="flex" justifyContent="center">
            <Button
              disabled={btnDisabled}
              className="size-3"
              type="submit"
              variant="contained"
              color="inherit"
              style={{
                background: !btnDisabled ? primary : gray,
                color: "#fff"
              }}
              size="large"
              sx={{ width: "fit-content", mb: 1 }}
            >
              {isLoading ? "loading..." : "Запази"}
            </Button>
          </Box>
        ) : (
          <Box mb={2}>
            <Button
              disabled={btnDisabled}
              className="size-3"
              type="submit"
              variant="contained"
              color="inherit"
              style={{
                background: !btnDisabled ? primary : gray,
                color: "#fff",
                width: "min-content",
                float: "right"
              }}
              size="large"
              sx={{ width: "fit-content", mb: 1 }}
              endIcon={<SaveOutlinedIcon />}
            >
              {isLoading ? "loading..." : "Запиши"}
            </Button>

            <div style={{ clear: "both" }} />
          </Box>
        )}
      </form>
    );
  };

  render() {
    const { subVariant, policy, type, isFromWizard } = this.props;
    let hideForm = false;
    if (subVariant === "expiring") {
      hideForm = true;
    }
    if (policy?.isPending && type === "policies" && !isFromWizard) {
      if (["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"].includes(policy?.productId) && ["signedByCustomer", "sentToInsurer"].includes(subVariant)) {
        hideForm = false;
      } else {
        hideForm = true;
      }
    }
    return !hideForm && <Box m={2}>{this.renderForm()}</Box>;
  }
}

export default UploadFileForm;
