import React, { useState } from "react";
import { TextField, Grid } from "@mui/material";
import { createPolicyFields } from "../../../../config/WizardLFE/CreatePolicy.page.config";
import withValidation from "../../../HOC/withValidation";
import { connect } from "react-redux";
import moment from "moment/moment";
import { setWizardStatus, updatePolicyDuringIssuance } from "../../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../../utils/notifications";
import _get from "lodash.get";
import WizardButton from "../../common/WizardButton";

const CreatePolicy = props => {
  const [loading, setLoading] = useState(false);
  const { nextStep, refreshData, setPassedData } = props;
  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      try {
        const dateFrom = _get(props, "formData.dateFrom");
        const dateFromFormatted = dateFrom ? moment(dateFrom, "DD-MM-YYYY").format("YYYY-MM-DD") : dateFrom;
        const requestPayload = {
          date: dateFromFormatted,
          policyNumber: props?.formData?.policyNumber?.trim(),
          policy: props?.policy,
          isFromWizard: true
        };
        setLoading(true);
        await updatePolicyDuringIssuance(requestPayload);
        await setWizardStatus({ policyDBId: props?.policy?._id, wizardStatus: "step1" });
        setPassedData({ policyNumber: props?.formData?.policyNumber?.trim() });
        refreshData();
        nextStep();
        successAlert("policy number and date set successfully!");
      } catch (e) {
        setLoading(false);
        if (e?.response?.data) errorAlert("Error: " + e.response.data);
        else if (e?.message) errorAlert("Error: " + e.message);
        else errorAlert("Error occurred!");
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          {createPolicyFields.map(f => (
            <TextField
              sx={{ mb: 1, mt: 1 }}
              key={f.name}
              placeholder={f.placeholder}
              fullWidth
              label={f.label}
              variant="outlined"
              size="small"
              name={f.name}
              value={props?.formData[f.name]}
              onChange={f.type === "percentage" ? props?.handlePercentageChange : props?.handleChange}
              helperText={props?.errors[f.name]}
              error={!!props?.errors[f.name]}
            />
          ))}
          <WizardButton disabled={loading} handleSubmit={handleSubmit} loading={loading} />
        </div>
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(CreatePolicy, { txtFields: createPolicyFields }));
