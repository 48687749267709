import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ManualAutomaticRadioButtons from "../../common/ManualAutomaticRadioButtons";
import ManualPolicyIssuance from "./ManualPolicyIssuance";
import AutomaticPolicyIssuance from "./AutomaticPolicyIssuance";
import { isAutomaticAndManualPolicyIssuanceActive } from "../../../../config/mainConfig/helpers";
import _get from "lodash.get";
const PolicyIssuance = props => {
  const {
    policy: { insurerId },
    currentProduct
  } = props;
  const isAutomaticAndManualPolicyIssuanceActiveConfig = isAutomaticAndManualPolicyIssuanceActive(currentProduct, insurerId);
  const [selectedTab, setSelectedTab] = useState(isAutomaticAndManualPolicyIssuanceActiveConfig ? "automatic" : "manual");
  const [loading, setLoading] = useState(false);
  const [radiosDisabled, setRadiosDisabled] = useState(!isAutomaticAndManualPolicyIssuanceActiveConfig);
  const handleChange = e => {
    setSelectedTab(e?.target?.value);
  };
  useEffect(() => {
    // if (_get(props.policy, "policyData.isNotRegisteredVehicle", false)) { // todo когато се добави функционалността за "нерегистрирани" за Каско + ГО
    //   setSelectedTab("manual");
    //   setRadiosDisabled(true);
    // }
    let isForeigner = false;
    const ownerData = _get(props, "policy.policyData.savedCar.ownerData");
    const insuredPersons = _get(props, "policy.policyData.persons", []).filter(p => p.isInsured === undefined || p.isInsured);
    const owner = _get(insuredPersons, "[0]", {});
    if (ownerData) {
      isForeigner = owner?.isForeigner || (ownerData?.country && ownerData.country !== "BG");
    } else {
      isForeigner = owner?.isForeigner;
    }

    if (isForeigner) {
      setSelectedTab("manual");
      setRadiosDisabled(true);
    }
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "75%" }}>
          <ManualAutomaticRadioButtons loading={loading} handleChange={handleChange} selectedTab={selectedTab} disabled={radiosDisabled} />
          {selectedTab === "manual" && <ManualPolicyIssuance {...props} loading={loading} setLoading={setLoading} />}
          {selectedTab === "automatic" && <AutomaticPolicyIssuance {...props} loading={loading} setLoading={setLoading} setRadiosDisabled={setRadiosDisabled} />}
        </div>
      </Grid>
    </Grid>
  );
};

export default PolicyIssuance;
